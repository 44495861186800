export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Node',
        possibleTypes: [
          {
            name: 'DailyCheckin',
          },
          {
            name: 'CoursewareStudySection',
          },
          {
            name: 'CoursewareLesson',
          },
          {
            name: 'Level',
          },
          {
            name: 'CoursewareCourse',
          },
          {
            name: 'ReviewNote',
          },
          {
            name: 'ReviewLesson',
          },
          {
            name: 'ReviewNotebook',
          },
          {
            name: 'ReviewNotePracticeStudySection',
          },
          {
            name: 'ClassTopic',
          },
          {
            name: 'ClassBooking',
          },
          {
            name: 'ClassBookingNote',
          },
          {
            name: 'User',
          },
          {
            name: 'LanguageBuddyChat',
          },
          {
            name: 'UserChat',
          },
          {
            name: 'UserChatMessage',
          },
          {
            name: 'StudySession',
          },
          {
            name: 'StudySetChat',
          },
          {
            name: 'StudySet',
          },
          {
            name: 'AssistantChat',
          },
          {
            name: 'BugReportingChat',
          },
          {
            name: 'CoursewareChat',
          },
          {
            name: 'FeedbackChat',
          },
          {
            name: 'FeedbackConversationsChat',
          },
          {
            name: 'LessonChat',
          },
          {
            name: 'PracticeChat',
          },
          {
            name: 'PremiumSampleChat',
          },
          {
            name: 'TeacherChat',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'StudySection',
        possibleTypes: [
          {
            name: 'CoursewareStudySection',
          },
          {
            name: 'ReviewNotePracticeStudySection',
          },
          {
            name: 'BaseStudySection',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'DialogSegment',
        possibleTypes: [
          {
            name: 'DialogBaseSegment',
          },
          {
            name: 'DialogPaywallSegment',
          },
          {
            name: 'DialogQuestionSegment',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MessageItem',
        possibleTypes: [
          {
            name: 'TextMessageItem',
          },
          {
            name: 'GifMessageItem',
          },
          {
            name: 'AudioMessageItem',
          },
          {
            name: 'AudioCarouselMessageItem',
          },
          {
            name: 'VideoMessageItem',
          },
          {
            name: 'WebLinkMessageItem',
          },
          {
            name: 'ImageMessageItem',
          },
          {
            name: 'CardMessageItem',
          },
          {
            name: 'CardCarouselMessageItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CardMessageItemBodyItem',
        possibleTypes: [
          {
            name: 'CardMessageItemBodyImage',
          },
          {
            name: 'CardMessageItemBodyText',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CoursewareReviewSummaryContentItem',
        possibleTypes: [
          {
            name: 'ReviewTopic',
          },
          {
            name: 'TextReviewContentItem',
          },
          {
            name: 'GifReviewContentItem',
          },
          {
            name: 'AudioReviewContentItem',
          },
          {
            name: 'AudioCarouselReviewContentItem',
          },
          {
            name: 'WebLinkReviewContentItem',
          },
          {
            name: 'VideoReviewContentItem',
          },
          {
            name: 'ImageReviewContentItem',
          },
          {
            name: 'CardReviewContentItem',
          },
          {
            name: 'CardCarouselReviewContentItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReviewTopicBodyItem',
        possibleTypes: [
          {
            name: 'ReviewExample',
          },
          {
            name: 'TextReviewContentItem',
          },
          {
            name: 'GifReviewContentItem',
          },
          {
            name: 'AudioReviewContentItem',
          },
          {
            name: 'AudioCarouselReviewContentItem',
          },
          {
            name: 'WebLinkReviewContentItem',
          },
          {
            name: 'VideoReviewContentItem',
          },
          {
            name: 'ImageReviewContentItem',
          },
          {
            name: 'CardReviewContentItem',
          },
          {
            name: 'CardCarouselReviewContentItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReviewContentItem',
        possibleTypes: [
          {
            name: 'TextReviewContentItem',
          },
          {
            name: 'GifReviewContentItem',
          },
          {
            name: 'AudioReviewContentItem',
          },
          {
            name: 'AudioCarouselReviewContentItem',
          },
          {
            name: 'WebLinkReviewContentItem',
          },
          {
            name: 'VideoReviewContentItem',
          },
          {
            name: 'ImageReviewContentItem',
          },
          {
            name: 'CardReviewContentItem',
          },
          {
            name: 'CardCarouselReviewContentItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CardReviewContentItemBodyItem',
        possibleTypes: [
          {
            name: 'CardReviewContentItemBodyImage',
          },
          {
            name: 'CardReviewContentItemBodyText',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReviewNoteContentItem',
        possibleTypes: [
          {
            name: 'ReviewTopic',
          },
          {
            name: 'TextReviewContentItem',
          },
          {
            name: 'GifReviewContentItem',
          },
          {
            name: 'AudioReviewContentItem',
          },
          {
            name: 'AudioCarouselReviewContentItem',
          },
          {
            name: 'WebLinkReviewContentItem',
          },
          {
            name: 'VideoReviewContentItem',
          },
          {
            name: 'ImageReviewContentItem',
          },
          {
            name: 'CardReviewContentItem',
          },
          {
            name: 'CardCarouselReviewContentItem',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Chat',
        possibleTypes: [
          {
            name: 'UserChat',
          },
          {
            name: 'StudySetChat',
          },
          {
            name: 'AssistantChat',
          },
          {
            name: 'BugReportingChat',
          },
          {
            name: 'CoursewareChat',
          },
          {
            name: 'FeedbackChat',
          },
          {
            name: 'FeedbackConversationsChat',
          },
          {
            name: 'LessonChat',
          },
          {
            name: 'PracticeChat',
          },
          {
            name: 'PremiumSampleChat',
          },
          {
            name: 'TeacherChat',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'PremiumPricePlan',
        possibleTypes: [
          {
            name: 'PremiumPricePlanAppStore',
          },
          {
            name: 'PremiumPricePlanGooglePlay',
          },
          {
            name: 'PremiumPricePlanVoucher',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'CreditBundle',
        possibleTypes: [
          {
            name: 'StripeCheckoutCreditBundle',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConfienceData',
        possibleTypes: [
          {
            name: 'SpeechTranscriptionData',
          },
          {
            name: 'SpeechWordScoreData',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'DialogQuestion',
        possibleTypes: [
          {
            name: 'DialogMultipleChoiceTextQuestion',
          },
          {
            name: 'DialogScrambleQuestion',
          },
          {
            name: 'DialogSpeakingQuestion',
          },
        ],
      },
    ],
  },
};
export default result;
