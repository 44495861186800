import { ApolloClient, DefaultOptions } from 'apollo-client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ServerError } from 'apollo-link-http-common';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import fetch from 'isomorphic-fetch';

import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  IntrospectionResultData,
} from 'apollo-cache-inmemory';

import introspectionResult from '../introspection-result';
import { getAuthToken, clearAuthToken } from '../auth';
import { getSecondaryLanguage } from '../persistentStorage';
import { ApiHostManager } from './ApiHostManager';
import { logger } from '../logger';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

const createApolloCache = () => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: introspectionResult as IntrospectionResultData,
  });

  return new InMemoryCache({
    addTypename: true,
    fragmentMatcher,
  });
};

const apiHostManager = new ApiHostManager();

const httpLink = new HttpLink({
  uri: () => `${apiHostManager.getApiHost()}/graphql`,
  fetch,
});

const errorLink = onError(
  ({ networkError, graphQLErrors, response, operation }) => {
    logger.error('Apollo Error Link', {
      error: networkError || graphQLErrors?.[0],
      graphQLErrors,
      response,
      operation,
    });
    if (networkError && (networkError as ServerError).statusCode === 401) {
      clearAuthToken();
    }
  },
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'accept-language': `en-us, ${getSecondaryLanguage()}`,
    },
  };
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

export default new ApolloClient({
  link,
  cache: createApolloCache(),
  defaultOptions,
});
