/* eslint-disable no-console */
type LogExtra = {
  error?: Error;
  [value: string]: any;
};

interface IdentifyProps {
  id: string;
  email: string;
}

class Logger {
  private email: string = '';

  get sentry() {
    if (typeof window !== 'undefined') {
      return (window as any).Sentry;
    }
    return undefined;
  }

  identify = ({ email, id }: IdentifyProps) => {
    if (this.sentry) {
      this.sentry.configureScope((scope: any) => {
        scope.setUser({
          id,
          email,
        });
      });
    }
    this.email = email;
  };

  error(msg: string, extra?: LogExtra) {
    this.captureWithSentry('error', msg, extra);
    console.error(msg, { ...extra });
  }

  warning = (msg: string, extra?: LogExtra) => {
    this.captureWithSentry('warning', msg, extra);
    console.warn(msg, { ...extra });
  };

  debug(msg: string, extra?: LogExtra) {
    this.captureWithSentry('debug', msg, extra);
    console.debug(msg, { ...extra });
  }

  info(msg: string, extra?: LogExtra) {
    this.captureWithSentry('info', msg, extra);
    console.info(msg, { ...extra });
  }

  showFeedbackDialog() {
    try {
      throw new Error('Send Feedback');
    } catch (error) {
      const eventId = this.sentry.captureException(error);
      this.sentry.showReportDialog({
        eventId,
        title: 'Thank you for reporting an issue',
        user: {
          email: this.email,
          name: this.email.split('@')[0].replace('.', ' '),
        },
      });
    }
  }

  private captureWithSentry = (
    level: 'error' | 'warning' | 'debug' | 'info',
    msg: string,
    extra?: LogExtra,
  ) => {
    if (!this.sentry) {
      console.log('Sentry undefined');
      return;
    }

    this.sentry.withScope((scope: any) => {
      scope.setLevel(level);
      const { error, ...meta } = extra ?? {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(meta)) {
        scope.setTag(key, value);
      }
      scope.setTag('message', msg);
      if (error) {
        this.sentry.captureException(error);
      } else {
        this.sentry.captureMessage(msg);
      }
    });
  };
}

export const logger = new Logger();
