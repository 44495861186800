// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-lookup-tsx": () => import("./../../../src/pages/adminLookup.tsx" /* webpackChunkName: "component---src-pages-admin-lookup-tsx" */),
  "component---src-pages-auth-tsx": () => import("./../../../src/pages/auth.tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-bookings-tsx": () => import("./../../../src/pages/bookings.tsx" /* webpackChunkName: "component---src-pages-bookings-tsx" */),
  "component---src-pages-chat-message-search-tsx": () => import("./../../../src/pages/chatMessageSearch.tsx" /* webpackChunkName: "component---src-pages-chat-message-search-tsx" */),
  "component---src-pages-courseware-tsx": () => import("./../../../src/pages/courseware.tsx" /* webpackChunkName: "component---src-pages-courseware-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-language-buddy-chat-tsx": () => import("./../../../src/pages/languageBuddyChat.tsx" /* webpackChunkName: "component---src-pages-language-buddy-chat-tsx" */),
  "component---src-pages-promo-code-lookup-tsx": () => import("./../../../src/pages/promoCodeLookup.tsx" /* webpackChunkName: "component---src-pages-promo-code-lookup-tsx" */),
  "component---src-pages-user-lookup-tsx": () => import("./../../../src/pages/userLookup.tsx" /* webpackChunkName: "component---src-pages-user-lookup-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */)
}

