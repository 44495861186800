import { UserAgentApplication } from 'msal';

const AUTH_TOKEN_KEY = 'AUTH_TOKEN';

let userAgentApplication: UserAgentApplication | undefined;

// only load redirect stuff if not in ssr, otherwise gatsby freaks out
if (typeof window !== 'undefined') {
  // create UserAgentApplication instance
  userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: process.env.MS_OAUTH_CLIENT_ID as string,
      authority: `https://login.microsoftonline.com/${process.env.MS_OAUTH_TENANT_ID}`,
    },
  });
}

export const login = async (): Promise<void> => {
  if (!userAgentApplication) return;
  const { idToken } = await userAgentApplication.loginPopup();
  const res = await fetch(`${process.env.API_HOST}/admin-oauth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ access_token: idToken.rawIdToken }),
  });
  const resJson = await res.json();
  if (resJson.error) {
    throw new Error(resJson.error);
  }
  if (!resJson.authToken) {
    throw new Error('Unable to sign in');
  }
  localStorage.setItem(AUTH_TOKEN_KEY, resJson.authToken);
};

export const getAuthToken = (): string | undefined =>
  localStorage.getItem(AUTH_TOKEN_KEY) || undefined;
export const clearAuthToken = (): void =>
  localStorage.removeItem(AUTH_TOKEN_KEY);

export const isAuthenticated = () =>
  !!(typeof localStorage !== 'undefined' && getAuthToken());
